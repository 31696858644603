<template>
  <el-header style="height: auto;z-index: 9999;">
    <div class="header uf uf-ac uf-pj fwrap-w">
      <!--$router.push({name: 'index'})-->
      <div class="logo uf uf-ac cp" @click="$router.push({name: 'index'})">
        <img src="../assets/img/logo-sp.png" alt="">
        <div class="logo-word ml-3 tac">
          <div class="fwb">开放式产品服务化支撑平台</div>
          <div class="f14 op-08"><span>——</span><span class="text-space">国家重点研发计划支持</span><span>——</span></div>
        </div>
      </div>
      <div class="uf-f1">
        <slot name="center-box">
        </slot>
      </div>
      <div class="uf header-right">
          <el-popover
                  placement="top"
                  width="150"
                  trigger="hover">
              <div class="p-2 tac" v-if="loginStatus">
                  <div class="mb-1">
                      <el-button class="wi100" size="small" type="primary" plain @click="$router.push({name: 'manage'})">个人中心</el-button>
                  </div>
                  <div class="mb-1">
                      <el-button class="wi100 " size="small" type="primary" plain @click="$router.push({name: 'shoppingCart'})">
                          <i class="el-icon-shopping-cart-2" style="margin-right: 2px;"></i>购物车
                      </el-button>
                  </div>
                  <div>
                      <el-button class="wi100" size="small" type="danger" plain @click="logout">退出</el-button>
                  </div>
              </div>
              <div class="tac" v-else>
                  <span class="f14 op-07">你好，请登录</span>
              </div>
            <div class="uf uf-ac cp" slot="reference" style="position:relative; margin-right: 50px">
              <el-avatar class="mr-2" :size="35" :src="loginStatus ? $cons.SERVER_PATH + userHead : defAvatarUrl"></el-avatar>
              <template>
                <div class="f16 op-08 c-white" v-if="loginStatus">{{ name }}</div>
                <el-button v-else size="mini" type="primary" @click="$router.push({name: 'login'})">登录 / 注册</el-button>
              </template>
            </div>
          </el-popover>
          <el-badge :value="unRead" :hidden="unRead <= 0" class="item" style="position:relative; left:-30px;">
              <el-button size="small" icon="el-icon-chat-dot-round" circle @click="$router.push({name: 'remind'})"></el-button>
          </el-badge>
      </div>
    </div>
  </el-header>
</template>

<script>
  import {clearLoginInfo} from '../utils'
  export default {
    name: 'page-header',
    props: {},
    data() {
      return {
        defAvatarUrl: require('../../public/img/male.jpg'),
        userAvatarUrl: '',
        showHeaderBadge: true,
      }
    },
    computed: {
      name: {
        get () { return this.$store.state.user.name },
      },
      loginStatus: {
        get () { return this.$store.state.user.loginStatus },
        set (val) { this.$store.commit('user/updateLoginStatus', val) }
      },
      unRead: {
        get () { return this.$store.state.user.unRead },
        set (val) { this.$store.commit('user/updateUnRead', val) }
      },
        userHead: {
            get () { return this.$store.state.user.userHead },
            set (val) { this.$store.commit('user/updateUserHead', val) }
        }
    },
    watch: {

    },
    created () {
    },
      methods: {
      logout () {
        this.$confirm('确认退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.SYS.LOGOUT),
            method: 'post'
          }).then(({data}) => {
            clearLoginInfo()
            this.$router.push({name: 'index'})
          })
        }).catch(() => {})
      },
    }
  }
</script>
<style>
  .el-header {
    z-index: 999;
    background: linear-gradient(rgba(0,0,0,.6), transparent);
    padding: 15px 20px;
  }

  .header .el-menu--horizontal .el-submenu .el-submenu__title, .header .el-submenu__title {
    font-size: 16px;
    color: #333333;
  }
  .logo-word {
    color: white;
    font-size: 26px;
    text-shadow: 0 0 8px rgba(0,0,0,.3);
  }
  .el-menu.el-menu--horizontal, .el-menu--horizontal > .el-menu-item {
    border-bottom: none;
  }

  .el-menu--horizontal > .el-menu-item {
    font-size: 16px;
    color: #333333;
  }

  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background: rgba(64, 158, 255, .1);
    color: #409EFF;
  }

  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #409EFF;
    background: rgba(64, 158, 255, .1);
  }

  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    /* outline: 0; */
    /* color: #303133; */
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none;
    /*background: rgba(64, 158, 255, .1);*/
    color: #409EFF;
  }

  .el-dialog__wrapper .logo-img {
    width: 40px;
  }
  .text-space {
    letter-spacing: 12px;
    margin-right: 10px;
    margin-left: 10px;
  }
  @media only screen and (max-width: 500px) {
      .text-space {
          letter-spacing: 8px;
          margin-right: 5px;
          margin-left: 5px;
      }
  }

</style>
